<!--门店平台账号管理-->
<template>
    <div class="mainBox">
        <!--场景搜索-->
        <div class="selectionBar">
            <div class="flex flex-ai-c flex-jc-sb flex-wrap">
                <label>场景：</label>
                <el-button type="primary" block size="small" @click="edit()">添加账号</el-button>
            </div>
        </div>
        <!--主要内容-->
        <div class="table" style="height:calc( 100% - 175px )">
            <el-table :data="list"  stripe :header-cell-style="{background:'#fafdff',color:'#606266',fontWeight:'normal'}" @sort-change='getList()' height="calc( 100% - 41px )">
                <el-table-column prop="name" label="姓名" show-overflow-tooltip></el-table-column>
                <el-table-column prop="username" label="用户名" show-overflow-tooltip></el-table-column>
                <el-table-column prop="mobile" label="手机号" show-overflow-tooltip></el-table-column>
                <el-table-column label="操作" align="center" fixed="right" width="240">
                    <template slot-scope="scope">
                        <!-- <el-button type="success" plain size="mini" @click="showDetail(scope.row,'show')">查看</el-button> -->
                        <el-button type="success" plain size="mini" @click="edit(scope.row)">编辑</el-button>
                        <el-button type="danger" plain size="mini" @click="deletes(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="paging">
                <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
            </div>
        </div>

        <!-- 新建框 -->
        <el-dialog :title="title" custom-class="way" :visible.sync="dialogFormVisible" :modal-append-to-body="false" :close-on-click-modal="false" width="500px">
            <el-form ref="formAuth" :rules="formRules" :model="formData" label-position="top" size="small" class="alertForm">
                <el-form-item label="姓名" prop="name" style="width:100%">
                    <el-input v-model="formData.name" placeholder="请输入姓名" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="用户名" prop="username" style="width:100%">
                    <el-input v-model="formData.username" placeholder="请输入姓名" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="mobile" style="width:100%">
                    <el-input v-model="formData.mobile" placeholder="请输入手机号" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="密码(如无需修改留空即可)" :prop="ispwd?'password':''" style="width:100%">
                    <el-input v-model="formData.password" placeholder="请输入密码(如无需修改留空即可)" show-password autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="dialogFormVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="submit()">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import {houseAdminListApi}  from '@/api/house.js';
    import {adminSaveApi,adminDeleteApi}  from '@/api/admin.js';
    export default {
        data() {
            return {
                formRules:{
                    name: [{
                        required: true,
                        message: '请输入姓名',
                        trigger: 'blur'
                    }],
                    username: [{
                        required: true,
                        message: '请输入账号',
                        trigger: 'blur'
                    }],
                    mobile: [{
                        required: true,
                        pattern: /^1[0-9][0-9]\d{8}$/,
                        message: '请输入手机号',
                        trigger: 'change'
                    }],
                    password: [{
                        required: true,
                        message: '请输入密码',
                        trigger: 'blur'
                    }],
                },
                formData:{}, //详情数据

                list:[], //商品列表

                title:'添加账号',
                curr: 1, //页码
                row: 10, //每页条数
                pages: 1, //总页数
                count: 0, //总条数

                dialogFormVisible:false, //弹出框状态
                ispwd:false, //编辑状态下密码是否可填状态
                
            }
        },
        mounted() {
            this.getList();
        },
        methods: {
            //门店账号列表
            getList: function(){
                houseAdminListApi({
                    curr:this.curr,
                    row:this.row
                }).then(response=>{
                    this.list = response.list;
                    this.curr = response.curr;
                    this.pages = response.pages;
                    this.count = response.count;
                })
            },
            //账号信息更改
            edit: function(param = {}){
                this.dialogFormVisible = true;
                if(typeof param.id == 'undefined'){
                    this.title = '添加人员';
                    this.formData = {};
                    this.ispwd = true;
                }else{
                    this.ispwd = false;
                    this.title = '编辑人员';
                    this.formData = JSON.parse(JSON.stringify(param));
                }
            },

            //删除账号
            deletes: function(id){
                this.$confirm('此操作将永久删除该文件, 是否继续', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    adminDeleteApi({
                        id:id
                    }).then(()=>{
                        this.getList();
                        this.$message({
                            type: 'success',
                            message: '已删除!'
                        });
                    })
                }).catch(()=>{})
            },

            //保存
            submit: function(){
                this.$refs['formAuth'].validate((valid) => {
                    if (valid) {
                        var warehouse_id = JSON.parse(localStorage.getItem('houseInfo')).is_admin == 3 ? JSON.parse(localStorage.getItem('houseInfo')).warehouse_id :  JSON.parse(localStorage.getItem('houseInfo')).id;
                        console.log(warehouse_id)
                        var pwd = this.formData.password ? this.$md5(this.formData.password) : this.formData.password
                        adminSaveApi({
                            id:this.formData.id,
                            name:this.formData.name,
                            username:this.formData.username,
                            mobile:this.formData.mobile,
                            password:pwd,
                            warehouse_id:warehouse_id
                        }).then(()=>{
                            this.closeDialog();
                            this.getList();
                            this.$message({
                                type: 'success',
                                message: '保存成功!'
                            });
                        })
                    }
                })
            },
            //关闭弹窗
            closeDialog:function(){
                this.$refs['formAuth'].resetFields();
                this.dialogFormVisible = false;
            },

            //翻页
            currentChange: function(curr) {
                this.curr = curr;
                this.getList();
            },
            //改变每页条数
            sizeChange: function(row) {
                this.row = row;
                this.getList();
            },
        },
    }
</script>