import request from '@/http.js'

/**
 * @description 账号管理 -- 列表
 * @param {Object} param data {Object} 传值参数
 */
export function adminListApi (param) {
    return request.post(
        '/admin.admin/index',
        param
    )
}

/**
 * @description 账号管理 -- 保存
 * @param {Object} param data {Object} 传值参数
 */
 export function adminSaveApi (param) {
    return request.post(
        '/admin.admin/save',
        param
    )
}

/**
 * @description 账号管理 -- 删除
 * @param {Object} param data {Object} 传值参数
 */
 export function adminDeleteApi (param) {
    return request.post(
        '/admin.admin/delete',
        param
    )
}